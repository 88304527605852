/* eslint-disable import/no-unresolved */
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { isBuildTime, trackViewItemList } from '@powdr/utils';

// TODO: if we ever have multiple different types of tracking besides product groups, we can
// split this into different subcomponents of tracking
export const Tracking = ({
  trackableProducts,
}) => {
  if (trackableProducts.length === 0 || isBuildTime()) return null;

  useEffect(() => {
    trackViewItemList(trackableProducts);
  }, [trackableProducts]);

  return null;
};

Tracking.propTypes = {
  trackableProducts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

Tracking.defaultProps = {};
